<template>
    <b-modal :active.sync="isActive" :width="1200" :canCancel="['escape', 'outside']" style="z-index: 9999"><!-- scroll="keep"  -->
        <div class="has-background-white confirm-services-modal-container card">
            <div class="margin-2">
                <h2 class="title is-3 no-margin has-text-centered">Xử lý khách chờ gội</h2>
            </div>
            <b-table
                :data="filteredBooking"
                bordered
            >

                <template slot-scope="props">
                    <b-table-column field="id" label="STT" width="40" numeric centered> 
                        {{ props.index + 1 }}  
                    </b-table-column>

                    <b-table-column field="productName" label="Tên khách hàng" centered>
                        {{ props.row.customerName }}
                    </b-table-column>

                    <b-table-column field="productPrice" label="Thời gian chờ gội" centered width="80">
                        <TimeCounter :startTime="props.row.isCallTime"/>
                    </b-table-column>

                    

                    <b-table-column field="stylist" label="Stylist" centered>
                        {{ getDetailStylist(props.row.stylistId).fullName }}
                    </b-table-column>

                    <b-table-column field="skinner" label="Skinner" centered width="180">
                        <!--<div v-if="getDetailSkinner(props.row.skinnerId)" >
                            <b-button size="is-small" type="is-success" @click="openPrintBill(props.row.id)">Đổi skinner</b-button>
                            {{ getDetailSkinner(props.row.skinnerId).fullName }}
                        </div>
                        <div v-else>
                            <b-button size="is-small" type="is-success">Xếp skinner</b-button>
                        </div>-->
                        <b-field v-if="getDetailSkinner(props.row.skinnerId)">
                            <b-input
                                placeholder="Tên"
                                disabled
                                :value="getDetailSkinner(props.row.skinnerId).fullName"
                                size="is-small"
                                
                            />
                            <p>
                                <b-button class="button is-primary" size="is-small" @click="openPrintBill(props.row.id)">Đổi</b-button>
                            </p>
                        </b-field>
                    </b-table-column>

                    <!--<b-table-column field="discount" label="Đang gội khách khác" centered>
                        {{ skinnerStatus[props.row.skinnerId || 0] ? 'Có' : 'Không' }}
                    </b-table-column>-->

                    <b-table-column field="timeServingCustomer" label="Skinner đã gội khách khác được" centered width="100">
                        <!--<template slot="header" slot-scope="{ column }" >
                            <div style="background-color: #455a64; width: 100%; height: 100%;">
                                <span style="color: white;"> {{ column.label }}</span>
                            </div>
                        </template>-->
                        <template slot="header" slot-scope="{ column }">
                            <span style="color: #c62828"> {{ column.label }}</span>
                        </template>
                        <div v-if="skinnerStatus[props.row.skinnerId || 0]">
                            <TimeCounter :startTime="skinnerStatus[props.row.skinnerId || 0].inProcedureTime"/>
                        </div>
                    </b-table-column>

                    <b-table-column field="ongoingServices" label="Dịch vụ skinner đang làm" centered>
                        <template slot="header" slot-scope="{ column }">
                            <span style="color: #c62828"> {{ column.label }}</span>
                        </template>
                        {{ skinnerStatus[props.row.skinnerId || 0] ? getServiceNames(skinnerStatus[props.row.skinnerId || 0].serviceIds) : '' }}
                    </b-table-column>

                    <b-table-column field="estimateRemainingTime" label="Ước tính hoàn thành sau" centered>
                        <template slot="header" slot-scope="{ column }">
                            <span style="color: #c62828"> {{ column.label }}</span>
                        </template>
                        {{ getSkinnerEstimateRemainingTime(props.row) }} phút
                    </b-table-column>
                </template>
            </b-table>
            
        </div>
        <ModalCloseButton :isTopLevel="true"/>
    </b-modal>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapMutations, mapActions } from 'vuex';

import ModalCloseButton from "@/components/Shared/ModalCloseButton";
import TimeCounter from '@/components/Shared/TimeCounter';

export default {
    name: "OrganizeSkinnerModal",
    components: {
        ModalCloseButton,
        TimeCounter
    },
    methods: {
        ...mapMutations(['openBookingModal']),
        ...mapActions(['setSkinnerStatus']),
        openPrintBill(bookingId) {
            this.openBookingModal({ bookingId, type: 'printBill' })
        },
        getFullSkinnerServices(serviceIds) {
            if (!serviceIds) return [];
            let services = JSON.parse(serviceIds);

            let servicesFull = []

            services.forEach(s => {
                const sFull = this.services.find(i => i.id == s.Id);

                const isSkinnerService = sFull.configDepartment == 0 || sFull.configDepartment == 2;
                if (isSkinnerService) servicesFull.push(sFull);
            })

            return servicesFull;
        },
        getServiceNames(serviceIds) {
            const servicesFull = this.getFullSkinnerServices(serviceIds);
            const serviceNames = servicesFull.map(s => s.serviceName);
            return serviceNames.join(', ');
        },
        getTotalEstimateTime(serviceIds) {
            const servicesFull = this.getFullSkinnerServices(serviceIds);
            const totalEstimateTime = servicesFull.reduce((p, c) => p + c.skinnerTimeEstimate, 0);
            return totalEstimateTime;
        },
        
        getSkinnerEstimateRemainingTime(bill) {
            const skinnerStatus = this.skinnerStatus[bill.skinnerId || 0];
            if (!skinnerStatus) return 0;
            const skinnerStartTime = skinnerStatus.inProcedureTime;
            const totalEstimateTime = this.getTotalEstimateTime(skinnerStatus.serviceIds);
            const skinnerEstimateEndTime = moment(skinnerStartTime).add(totalEstimateTime, 'minutes');
            //console.log('skinnerEstimateEndTime', skinnerEstimateEndTime)
            return Math.max(0, skinnerEstimateEndTime.diff(moment(), 'minutes'));
        }
    },
    data() {
        return {
            type: 'organizeSkinner',
            skinnerIdString: ''
        }
    },
    computed: {
        ...mapGetters(['isModalActive', 'bookings', 'getDetailStylist', 'getDetailSkinner', 'skinnerStatus', 'services']),
        isActive: {
            get() {
                return this.isModalActive[this.type];
            },
            set(value) {
                this.$store.commit("setModalActive", { type: this.type, isActive: value });
            }
        },
        filteredBooking() {
            const filteredBooking = this.bookings.filter(b => b.isMakeBill && !b.inProcedureTime && !b.completeBillTime);
            this.skinnerIdString = filteredBooking.filter(b => b.skinnerId).map(b => b.skinnerId).join(',');
            return filteredBooking;
        },
    },
    watch: {
        skinnerIdString: function(val) {
            console.log('skinnerIdString change', val)
            this.setSkinnerStatus(val);
        }
    }
}
</script>

<style scoped lang="scss">
.confirm-services-modal-container {
    padding: 2rem 2rem;
    max-height: 100%;
    overflow-y: auto;
}

.confirm-services-button-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;

    .confirm-services-button {
        padding-left: 100px;
        padding-right: 100px;
    }
}

.moneyContainer {
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: bold;
    float: right;

    display: flex;
    flex-direction: row;
    font-size: 18px;

    .moneyValue {
        width: 120px;
        text-align: center;
    }
}

.moneyContainer.margin-narrow {
    margin-top: 5px;
    margin-bottom: 5px;

    font-weight: 500;

    font-size: 16px;
}

</style>
