<template>
    <span>
        {{hour ? hour + ':' : ''}}{{ (minute >= 10 ? minute : '0' + minute) }}:{{ (second >= 10) ? second : '0' + second }}
    </span>
</template>

<script>
import moment from 'moment';

export default {
    name: "TimeCounter",
    props: ['startTime'],
    data() {
        return {
            hour: 0,
            minute: 0,
            second: 0
        }
        
    },
    methods: {
        countTime() {
            setInterval(() => {
                let lastTime = moment(this.startTime).unix();
                let currentTime = moment().unix();
                let diffTime = currentTime - lastTime;
                let duration = moment.duration(diffTime * 1000, "milliseconds");
                this.minute = duration.minutes();
                this.second = duration.seconds();
                this.hour = duration.hours(); 
            }, 1000);
        }
    },
    created() {
        this.countTime();
    }
}
</script>

<style scoped>
</style>
